import React, { useState, useEffect } from "react";
import QuestForm from "./QuestForm";
import QuestTable from "./QuestTable";
import QuestFileUploader from "./QuestFileUploader";
import { toast } from "react-toastify";
import axios from "axios";

const Quests = () => {
  const [questData, setQuestData] = useState([]); // Store parsed quest data
  const [questFiles, setQuestFiles] = useState([]); // Store uploaded files
  const [questName, setQuestName] = useState(""); // Store quest name
  const [pointsAllocated, setPointsAllocated] = useState(""); // Store allocated points

  // Generic sendRequest function for API calls
  async function sendRequest(data, url, method = "post", params = null) {
    const config = {
      method,
      url,
      headers: {
        "Content-Type": "application/json",
      },
    };

    // Trim questName if it exists in the data
    if (data && data.questName) {
      data.questName = data.questName.trim();
    }

    // If data is an array (like in batchAddQuestCompletions), trim questName for each item
    if (Array.isArray(data)) {
      data = data.map(item => ({
        ...item,
        questName: item.questName ? item.questName.trim() : item.questName
      }));
    }

    if (data) config.data = JSON.stringify(data);
    if (params) config.params = params;

    console.log("API Request:", config); // Log the request for debugging

    return await axios.request(config).catch(function (error) {
      if (error.response) {
        return error.response;
      } else {
        return error;
      }
    });
  }

  // Update quest data with the latest questName and pointsAllocated
  useEffect(() => {
    if (questData.length > 0) {
      const updatedQuestData = questData.map((record) => ({
        ...record,
        questName: questName, // Update with the latest quest name
        points: pointsAllocated, // Update with the latest points
      }));
      setQuestData(updatedQuestData);
    }
  }, [questName, pointsAllocated, questFiles]);

  // Handle form submission
  const handleQuestSubmit = async (event) => {
    event.preventDefault();
    try {
      // Show a loading toast message
      toast.info("Submitting data...", {
        autoClose: false,
        toastId: "submitToast",
      });

      const dataToSubmit = questData.map((record) => ({
        walletAddress: record.walletAddress,
        questName: record.questName.trim(), // Trim here as well for extra safety
        points: Number(record.points),
      }));

      const response = await sendRequest(
        { action: "batchAddQuestCompletions", questRecords: dataToSubmit },
        `${process.env.REACT_APP_API_URL}`
      );

      // Handle successful responses
      if (response && response.status === 200) {
        toast.update("submitToast", {
          render: "All quests uploaded successfully!",
          type: "success",
          autoClose: 5000,
        });
      } else {
        throw new Error("Error uploading quests");
      }
    } catch (error) {
      // Handle errors and update the toast message
      console.error("Error uploading quests:", error);
      toast.update("submitToast", {
        render: "Error uploading quests.",
        type: "error",
        autoClose: 5000,
      });
    }
  };

  // Handle form reset - clear all uploaded files and reset data
  const handleQuestReset = () => {
    setQuestData([]);
    setQuestFiles([]); // Clear the uploaded files
    setQuestName(""); // Reset the quest name
    setPointsAllocated(""); // Reset points allocation
  };

  return (
    <div className="quests-container p-4 pb-40">

      {/* Quest Form */}
      <QuestForm
        handleSubmit={handleQuestSubmit} // Pass the handleSubmit function
        handleReset={handleQuestReset} // Pass the handleReset function
        questName={questName}
        setQuestName={setQuestName}
        pointsAllocated={pointsAllocated}
        setPointsAllocated={setPointsAllocated}
      />

      {/* File Uploader */}
      <QuestFileUploader
        setQuestData={setQuestData} // Pass setQuestData to handle file upload
        questFiles={questFiles}
        setQuestFiles={setQuestFiles}
        questName={questName}
        points={pointsAllocated}
      />

      {/* Quest Data Table */}
      {questData.length > 0 ? (
        <QuestTable questData={questData} />
      ) : (
        <p className="form-card text-center m-4 -mt-10">
          No quest data available
        </p>
      )}
    </div>
  );
};

export default Quests;
