import React from 'react';

const QuestTable = ({ questData }) => {

  if (!questData || questData.length === 0) {
    return <p className="text-gray-700 dark:text-gray-200 text-center">No quest data available</p>;
  }

  // Sort the quest data by Ranking or fallback to the order they were uploaded
//   const sortedQuestData = [...questData].sort((a, b) => {
//     return parseInt(a.Ranking || 0, 10) - parseInt(b.Ranking || 0, 10);
//   });

  return (
    <div className="m-4 -mt-10 bg-[#d954ff57] p-4 rounded-b-3xl">
      <h2 className="text-center text-[1.5rem] pb-2">Quest Data</h2>
      <div className="overflow-x-auto">
      <table className="form-table">
          <thead>
            <tr>
              <th>Number</th>
              <th>Wallet Address</th>
              <th>Quest Name</th>
              <th>Points</th>
            </tr>
          </thead>
          <tbody>
            {questData.map((record, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{record.walletAddress}</td>
                <td>{record.questName || 'Unknown Quest'}</td>
                <td>{record.points || 0}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default QuestTable;
