import React, { useState, useEffect } from 'react';
import KOLForm from './KOLForm';
import KOLReferralsCountTable from './KOLReferralsCountTable';
import KOLSearch from './KOLSearch';
import { toast } from 'react-toastify';
import axios from 'axios';

const KOL = () => {
  const [referralCode, setReferralCode] = useState('');
  const [kols, setKOLs] = useState([]); // Initialize as an empty array
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Generic sendRequest function for API calls
  async function sendRequest(data, url, method = 'post', params = null) {
    const config = {
      method,
      url,
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify(data),
    };
    if (params) config.params = params;
    return await axios.request(config).catch(function (error) {
      if (error.response) {
        return error.response;
      } else {
        return error;
      }
    });
  }

  const handleSearch = async (kolAddress) => {
    try {
      const response = await sendRequest({
        action: 'getReferrals',
        walletAddress: kolAddress,
      }, `${process.env.REACT_APP_API_URL}`);
      
      if (response.data && response.data.data) {
        return response.data.data.numberOfReferrals;
      } else {
        console.error('Unexpected response structure:', response);
        return null;
      }
    } catch (error) {
      console.error('Error fetching referral count:', error);
      return null;
    }
  };

  const handleCreateKOL = async (walletAddress) => {
    try {
      const response = await sendRequest(
        { 
            action: "createNewKOLUser",
            walletAddress: walletAddress,
         },
        `${process.env.REACT_APP_API_URL}`
      );

      console.log("response: ", response);
      if (response.data && response.data.data.inviteCode) {
        setReferralCode(response.data.data.inviteCode);
        toast.success('KOL created successfully!');
      }
    } catch (error) {
      toast.error('Error creating KOL.');
      console.error('Error creating KOL:', error);
    }
  };

  useEffect(() => {
    const fetchKOLsAndReferrals = async () => {
      try {
        const response = await sendRequest(
          { action: 'getKOLReferrersWithCounts' },
          `${process.env.REACT_APP_API_URL}`
        );
        if (response.status === 200) {
          setKOLs(JSON.parse(response.data.data.body));
        } else {
          setError(new Error('Failed to fetch KOLs and referrals'));
        }
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchKOLsAndReferrals();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <div className="kol-container p-4">
      
      {/* KOL Form */}
      <KOLForm handleCreateKOL={handleCreateKOL} />

      {/* KOL Search */}
      <KOLSearch handleSearch={handleSearch} />

      {/* Display Referral Code */}
      {referralCode && (
        <div className="mt-6 p-4 bg-green-100 text-green-800 rounded-md">
          <p>Referral Code: <span className="font-bold">{referralCode}</span></p>
        </div>
      )}

      {/* KOL Referrals Count Table */}
      <KOLReferralsCountTable kols={kols} />
    </div>
  );
};

export default KOL;
