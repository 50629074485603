import React from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import Games from "./Games/Games";
import Quests from "./Quests/Quests";
import KOL from "./KOL/KOL";
import Login from "./Login/Login";
import Layout from "./Layout/Layout";
import { AuthProvider, useAuth } from "./Contexts/AuthContext";
import ProtectedRoute from "./Components/ProtectedRoute";
import CreateAdmin from "./CreateAdmin/CreateAdmin";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Analytics from "./Analytics/Analytics";

const AppContent = () => {
  const { isAuthenticated } = useAuth()
  return (
    <Router>

      <Routes>
        {/* Redirect root to login */}
        <Route path="/" element={<Navigate to="/login" replace />} />

        <Route path="/login" element={
          isAuthenticated ? <Navigate to="/analytics" replace /> : <Login />
        } />
        <Route element={<ProtectedRoute />}>
          <Route element={<Layout />}>
            <Route path="games" element={<Games />} />
            <Route path="quests" element={<Quests />} />
            <Route path="kol" element={<KOL />} />
            <Route path="create-admin" element={<CreateAdmin />} />
            <Route path="analytics" element={<Analytics />} />
          </Route>
        </Route>
        {/* Catch all route for 404 */}
        <Route path="*" element={<Navigate to="/login" replace />} />
      </Routes>
      <ToastContainer />
    </Router>
  );
};

const App = () => {
  return (
    <AuthProvider>
      <AppContent />
    </AuthProvider>
  );
}

export default App;
