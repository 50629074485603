import React from 'react';

const GameForm = ({
  options,
  gamePointsAllocated,
  setGamePointsAllocated,
  handleSubmit,
  handleReset,
  setSelectedGameName,
  selectedGameName,
  splitPoints,
  setSplitPoints,
}) => {
  // Handle game points change
  const handlePointsChange = (index, value) => {
    const updatedPoints = [...gamePointsAllocated];
    updatedPoints[index] = value;
    setGamePointsAllocated(updatedPoints);
  };

  // Handle split toggle change
  const handleSplitChange = (tier) => {
    setSplitPoints(prev => ({
      ...prev,
      [tier]: !prev[tier]
    }));
  };

  return (
    <form onSubmit={handleSubmit} className="">
      <div className='form-card'>
        <label className='form-label text-xl mb-2'>Choose a Game</label>
        <select
          value={selectedGameName}
          onChange={(e) => setSelectedGameName(e.target.value)}
          className="w-full p-2 text-lg bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm"
        >
          {options.map((option) => (
            <option key={option.value} value={option.value} className="text-lg">
              {option.label}
            </option>
          ))}
        </select>
      </div>

      <div className='form-card'>
        <label className='form-label text-xl mb-3'>Points Allocation</label>
        <div className="grid grid-cols-1 md:grid-cols-5 gap-3">
          {[
            { label: 'Top 1%', tier: 'top1' },
            { label: 'Top 5%', tier: 'top5' },
            { label: 'Top 10%', tier: 'top10' },
            { label: 'Top 20%', tier: 'top20' },
            { label: 'Top 80%', tier: 'top80' }
          ].map(({ label, tier }, index) => (
            <div key={index} className="flex flex-col">
              <div className="flex items-center justify-between mb-2">
                <label className="text-lg font-medium">{label}</label>
                <div className="flex items-center">
                  <label className="text-base mr-2">Split</label>
                  <input
                    type="checkbox"
                    checked={splitPoints[tier]}
                    onChange={() => handleSplitChange(tier)}
                    className="form-checkbox h-5 w-5 text-blue-600"
                  />
                </div>
              </div>
              <input
                type="number"
                value={gamePointsAllocated[index]}
                onChange={(e) => handlePointsChange(index, e.target.value)}
                className="p-3 text-lg bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm"
              />
            </div>
          ))}
        </div>
      </div>

      <div className="form-submit">
        <button type="submit" className="btn btn-primary me-5 text-lg px-6 py-2">
          Submit
        </button>
        <button type="button" onClick={handleReset} className="btn btn-secondary text-lg px-6 py-2">
          Reset
        </button>
      </div>
    </form>
  );
};

export default GameForm;
