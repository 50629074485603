import React from 'react';

const QuestForm = ({
  handleSubmit,
  handleReset,
  questName,
  setQuestName,
  pointsAllocated,
  setPointsAllocated,
}) => {
  return (
    <form onSubmit={handleSubmit} className="">
      <div className='form-card'>
        <label className='form-label'>Quest Name</label>
        <input
          type="text"
          value={questName}
          onChange={(e) => setQuestName(e.target.value)}
          className="w-full p-2 bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm"
          placeholder="Enter Quest Name"
        />
      </div>
      <div className='form-card'>
        <label className='form-label'>Points Allocation</label>
        <input
          type="number"
          value={pointsAllocated}
          onChange={(e) => setPointsAllocated(e.target.value)}
          className="w-full p-2 bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm"
          placeholder="Enter Points"
        />
      </div>
      <div className="form-submit">
        <button type="submit" className="btn btn-primary me-5">Submit</button>
        <button type="button" onClick={handleReset} className="btn btn-secondary">Reset</button>
      </div>
    </form>
  );
};

export default QuestForm;
