import React from 'react';

const GameTable = ({ gameData }) => {
  if (!gameData || gameData.length === 0) {
    return <p className="text-gray-700 dark:text-gray-200 text-center">No game data available</p>;
  }

  // Sort the game data by Ranking in ascending order
  const sortedGameData = [...gameData].sort((a, b) => {
    return parseInt(a.Ranking, 10) - parseInt(b.Ranking, 10);
  });

  return (
    <div className="m-4 -mt-10 bg-[#d954ff57] p-4 rounded-b-3xl">
      <h2 className="text-center text-[1.5rem] pb-2">Game Data</h2>
      <div className="overflow-x-auto">
        <table className="form-table">
          <thead>
            <tr>
              <th>Ranking</th>
              <th>Wallet Address</th>
              <th>Game Name</th>
              <th>Points</th>
            </tr>
          </thead>
          <tbody>
            {sortedGameData.map((record, index) => (
              <tr key={index} >
                <td>{record.Ranking}</td>
                <td>{record['Address']}</td>
                <td>{record['GameName'] || 'Unknown Game'}</td>
                <td>{record.Points || 0}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default GameTable;
