import React, { useEffect, useState } from 'react';
import { NavLink, Outlet, useLocation } from 'react-router-dom';
import { useAuth } from '../Contexts/AuthContext'; // Import useAuth hook
import './Layout.scss';
import IconAnalytics from '../Images/icon-analytics.webp';
import IconGames from '../Images/icon-games.webp';
import IconKOL from '../Images/icon-kol.webp';
import IconQuests from '../Images/icon-quests.webp';
import IconUser from '../Images/icon-user.webp';
import IconArrow from '../Images/icon-arrow.svg';
import ZookDonut from '../Images/zook_eatingdnut.webp';

const Layout = () => {
  const { logout } = useAuth(); // Get logout function from AuthContext
  const location = useLocation();
  const [pageTitle, setPageTitle] = useState('');
  const [mobileMenu, setMobileMenu] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenu(prevState => !prevState);
  }

  useEffect(() => {
    const currentLink = navlinks.find(link => link.to === location.pathname);
    if (currentLink) {
      setPageTitle(currentLink.name);
    }
  }, [location]);


  const navlinks = [
    {
      to: '/analytics',
      img: IconAnalytics,
      name: 'Analytics'
    },
    {
      to: '/games',
      img: IconGames,
      name: 'Games'
    },
    {
      to: '/quests',
      img: IconQuests,
      name: 'Quests'
    },
    {
      to: '/kol',
      img: IconKOL,
      name: 'KOL'
    },
    {
      to: '/create-admin',
      img: IconUser,
      name: 'Create User'
    },
  ]

  return (
    <div className='flex flex-row'>
      <button
        className="fixed top-0 right-6 md:right-10 lg:right-20 flex items-center rounded-t-0 text-white bg-[#D352FB] px-5 text-[1.3rem] border-2 border-t-0 rounded-b-2xl shadow-[4px_5px_0_#5C26B180] hover:scale-[1.1] transition-all z-20"
        onClick={logout}
      >
        {mobileMenu}
        <span>Logout</span>
        <img src={IconArrow} alt="Logout" className='w-[1rem] scale-x-[-1] ml-1' />
      </button>

      <nav className={`sidebar backdrop-blur-sm bg-[#5C26B1] bg-opacity-40 fixed flex border-2 border-white shadow-[4px_5px_0_#5C26B180]  z-20
      bottom-2 -translate-x-[50%] left-[50%] rounded-b-[.5rem] rounded-t-[4rem] flex-row pt-4 px-10
      lg:bottom-[auto] lg:-translate-x-0 lg:left-2 lg:rounded-l-[.5rem] lg:rounded-r-[4rem] lg:-translate-y-[50%] lg:top-[50%] lg:flex-col lg:py-10 lg:px-0 ${mobileMenu? 'active' : ''}`}>
        {navlinks.map((link, index) => (
          <NavLink
            key={index}
            to={link.to}
            onClick={()=>setMobileMenu(false)}
            className={({ isActive }) => `p-2 transition-all mx-2 lg:mx-0 text-white ${isActive ? "text-shadow-dark active" : ""}`}
          >
            <img src={link.img} alt={link.name} className='w-[1.8rem] lg:w-[2.5rem] m-auto -mb-1 transition-all' />
            <div className='text-center text-[1.2rem] whitespace-nowrap'>{link.name}</div>
          </NavLink>
        ))}
        <div className='btn-menu md:hidden' onClick={toggleMobileMenu}>menu</div>
      </nav>

      <main className="w-full p-2  pt-5 lg:p-10 lg:pl-40 pb-40">
        <div className="page-title flex items-center mb-5">
          <img src={ZookDonut} alt="Zook" className='w-[90px] md:w-[120px] z-10' />
          <div className='bg-[#7342BF] text-white mt-4 rounded-r-[3rem] -ml-10 text-shadow-secondary shadow-[4px_5px_0_#5C26B180] border-2 border-white
          px-10 text-[2rem] md:text-[2.5rem] lg:px-20 lg:text-[3rem] '>
            {pageTitle}
          </div>
        </div>
        <Outlet />
      </main>
    </div>
  );
};

export default Layout;
