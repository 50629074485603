import React from "react";
import Papa from "papaparse";
import FileUploader from "../Components/FileUploader";

const QuestFileUploader = ({
  setQuestData,
  questFiles,
  setQuestFiles,
  questName,
  points
}) => {
  // Handle file upload and parsing
  const handleUpload = (files) => {
    if (files.length > 0) {
      const file = files[0]; // Get the first file (allow only one)
      setQuestFiles([file]); // Replace previous files with the new one

      // Parse CSV files
      Papa.parse(file, {
        header: true,
        complete: (results) => {
          // Filter out rows where all values are either null, undefined, or empty strings
          const filteredData = results.data.filter((row) => {
            return Object.values(row).some(
              (value) => value !== null && value !== undefined && value !== ""
            );
          });

        // Extract the EVM Address and add questName and pointsAllocated to each record
          const extractedData = filteredData.map((row) => ({
            walletAddress: row["EVM Address"], // Extract the EVM Address
            questName: questName || "Unknown Quest", // Add the quest name
            points: points || 0, // Add the points allocated
          }));

          // Update parsed data in the parent state
          setQuestData(extractedData);
        },
        error: (error) => {
          console.error("Error parsing CSV:", error);
        },
      });
    }
  };

  // Handle file removal
  const handleRemoveFile = (index) => {
    setQuestFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  return (
    <div className='form-card z-10'>
      <label className='form-label'>Upload Quest Files</label>
      <FileUploader
        onDrop={handleUpload}
        files={questFiles}
        handleRemoveFile={handleRemoveFile}
      />
    </div>
  );
};

export default QuestFileUploader;
