import React from 'react';
import { useDropzone } from 'react-dropzone';

const FileUploader = ({ onDrop, files = [], handleRemoveFile }) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: '.csv',
    multiple: true,
  });

  return (
    <div className="">
      <div
        {...getRootProps()}
        className={`border-2 border-dashed text-[#5c26b180] border-[#5c26b180] p-8 rounded-lg flex justify-center items-center cursor-pointer transition-colors ${
          isDragActive ? 'border-blue-500' : 'border-gray-300 dark:border-gray-600'
        }`}
        style={{ minHeight: '150px' }}
      >
        <input {...getInputProps()} />
        {isDragActive ? (
          <p className="dark:text-gray-200">Drop the files here ...</p>
        ) : (
          <p className="dark:text-gray-200">Drag and drop CSV files here, or click to select files</p>
        )}
      </div>
      {files.length > 0 && (
        <ul className="mt-4">
          {files.map((file, index) => (
            <li key={index} className="flex justify-between items-center dark:text-gray-200">
              {file.name}
              {/* <button
                onClick={() => handleRemoveFile(index)}
                className="ml-4 text-red-500 hover:text-red-700 transition-colors"
              >
                Remove
              </button> */}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default FileUploader;
