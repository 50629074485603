import React, { useState } from 'react';

const KOLForm = ({ handleCreateKOL }) => {
  const [walletAddress, setWalletAddress] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();
    handleCreateKOL(walletAddress);
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <div className='form-card'>
        <label className="form-label">Create KOL</label>
        <input
          type="text"
          value={walletAddress}
          onChange={(e) => setWalletAddress(e.target.value)}
          className="w-full p-2 bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm"
          placeholder="KOL Wallet Address"
        />

        <div className="flex justify-end mt-4">
          <button type="submit" className="btn btn-primary">
            Create KOL
          </button>
        </div>
      </div>
    </form>
  );
};

export default KOLForm;
