import React, { useState } from 'react';

function KOLSearch({ handleSearch }) {
  const [kolAddress, setKolAddress] = useState('');
  const [referralCount, setReferralCount] = useState(null);

  const onSearch = async (event) => {
    event.preventDefault();
    const count = await handleSearch(kolAddress);
    setReferralCount(count);
  };

  return (
    <form onSubmit={onSearch} className="space-y-4 mb-6">
      <div className='form-card'>
        <label className="form-label">Referrals Count Lookup</label>
        <input
          type="text"
          value={kolAddress}
          onChange={(e) => setKolAddress(e.target.value)}
          className="w-full p-2 bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm"
          placeholder="Paste KOL address here"
        />

        <div className="flex justify-between items-center mt-4">
          <div>
            {referralCount !== null && (
              <p className="font-bold">Number of referrals: {referralCount}</p>
            )}
          </div>
          <button type="submit" className="btn btn-primary">
            Search
          </button>
        </div>
      </div>
    </form>
  );
}

export default KOLSearch;
