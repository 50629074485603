import React, { useState } from 'react';
import { useAuth } from '../Contexts/AuthContext';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const CreateAdmin = () => {
  const [email, setEmail] = useState('');
  const [role, setRole] = useState('Admin');
  const [message, setMessage] = useState('');
  const { authToken } = useAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage('');

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_CONSOLE_API_URL}`,
        {
          action: 'createUser',
          email,
          role,
          authToken
        },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );

      if (response.data && response.data.message) {
        toast.success(response.data.message);
        setEmail('');
        setMessage(response.data.message);
      } else {
        toast.error('Failed to create user. Please try again.');
        setMessage('Failed to create user. Please try again.');
      }
    } catch (error) {
      console.error('Error creating user:', error);
      toast.error('An error occurred while creating the user.');
      setMessage('An error occurred while creating the user.');
    }
  };

  return (
    <div className="user-container p-4">
      <form onSubmit={handleSubmit} className="space-y-4">
        <div className="form-card">
          <label htmlFor="email" className="form-label">Email:</label>
          <input
            type="email"
            id="email"
            placeholder='User Email Address'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            className="w-full px-3 py-2 border rounded"
          />
        </div>

        <div className="form-card">
          <label htmlFor="role" className="form-label">Role:</label>
          <select
            id="role"
            value={role}
            onChange={(e) => setRole(e.target.value)}
            className="w-full px-3 py-2 border rounded"
          >
            <option value="Admin">Admin</option>
            <option value="Regular">Regular</option>
          </select>
        </div>

        <div className="form-submit">
          <button type="submit" className="btn btn-primary">
            Create User
          </button>
        </div>
      </form>
      {message && (
        <p className="mt-4 text-center text-green-600">{message}</p>
      )}
    </div>
  );
};

export default CreateAdmin;