import React from 'react';

const KOLReferralsCountTable = ({ kols }) => {
  if (!kols || kols.length === 0) {
    return <div className='form-card text-center'>No KOLs found.</div>;
  }

  return (
    <div >
      <table className='form-table m-4'>
        <thead>
          <tr>
            <th>KOL Address</th>
            <th>Referral Count</th>
          </tr>
        </thead>
        <tbody>
          {kols.map(kol => (
            <tr key={kol.walletAddress}>
              <td>{kol.walletAddress}</td>
              <td>{kol.referralCount}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default KOLReferralsCountTable;