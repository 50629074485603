import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { jwtDecode } from 'jwt-decode'; // Import the correct library for decoding JWTs
import { useWeb3ModalAccount } from '@web3modal/ethers/react';

export const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [authToken, setAuthToken] = useState(null);
  const { address } = useWeb3ModalAccount();

  const checkTokenValidity = useCallback(() => {
    const token = localStorage.getItem('jwt');
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000;

        if (decodedToken.exp < currentTime || decodedToken.walletAddress !== address) {
          logout();
        } else {
          setIsAuthenticated(true);
          setAuthToken(token);
        }
      } catch (error) {
        console.error('Invalid token:', error);
        logout();
      }
    } else {
      setIsAuthenticated(false);
    }
  }, [address]);

  useEffect(() => {
    checkTokenValidity();
  }, [checkTokenValidity]);

  const login = useCallback((token) => {
    localStorage.setItem('jwt', token);
    setAuthToken(token);
    setIsAuthenticated(true);
    checkTokenValidity()
  }, [checkTokenValidity]);

  const logout = useCallback(() => {
    localStorage.removeItem('jwt');
    setIsAuthenticated(false);
    setAuthToken(null);
  }, []);

  const value = {
    isAuthenticated,
    logout,
    login,
    authToken,
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
