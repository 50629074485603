import React, { useState, useEffect } from "react";
import GameForm from "./GameForm";
import GameTable from "./GameTable";
import GameFileUploader from "./GameFileUploader";
import { toast } from "react-toastify";
import axios from "axios";

const Games = () => {
  const [gameData, setGameData] = useState([]); // Store raw parsed game data
  const [processedGameData, setProcessedGameData] = useState([]); // Store processed data with points allocated
  const [gameFiles, setGameFiles] = useState([]); // Store uploaded files
  const [selectedGameName, setSelectedGameName] = useState(""); // Store selected game name
  const [gamePointsAllocated, setGamePointsAllocated] = useState([
    "350",
    "300",
    "200",
    "80",
    "40",
  ]); // Default points allocation
  const [splitPoints, setSplitPoints] = useState({
    top1: false,
    top5: false,
    top10: false,
    top20: false,
    top80: false
  });

  // Hardcoded game name options
  const [gameOptions, setGameOptions] = useState([
    { value: "Game 1", label: "Game 1" },
    { value: "Game 2", label: "Game 2" },
    { value: "Game 3", label: "Game 3" },
    { value: "Game 4", label: "Game 4" },
    { value: "Game 5", label: "Game 5" },
    { value: "Game 6", label: "Game 6" },
    { value: "Game 7", label: "Game 7" },
    { value: "Game 8", label: "Game 8" },
  ]);

  // Generic sendRequest function for API calls
  async function sendRequest(data, url, method = "post", params = null) {
    console.log("data: ", data);
    const config = {
      method,
      url,
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(data),
    };
    if (params) config.params = params;
    return await axios.request(config).catch(function (error) {
      if (error.response) {
        return error.response;
      } else {
        return error;
      }
    });
  }

  // Generic fetchRequest function for GET API calls
  async function fetchRequest(url, params = null) {
    console.log("Fetching data from: ", url);

    // Configure the request
    const config = {
      method: "get",
      url,
      headers: {
        "Content-Type": "application/json",
      },
      params, // Attach query parameters if provided
    };

    try {
      // Make the request using axios
      const response = await axios.request(config);
      return response;
    } catch (error) {
      // Handle errors
      if (error.response) {
        console.error("Error response: ", error.response);
        return error.response; // Return the error response
      } else {
        console.error("Error: ", error);
        return error; // Return the error if no response is available
      }
    }
  }

  // Handle form submission
  const handleGameSubmit = async (event) => {
    event.preventDefault();
    try {
      // Show a loading toast message
      toast.info("Submitting data...", {
        autoClose: false,
        toastId: "submitToast",
      });

      // Process processed game data in batches
      const batchSize = 25;
      let response;

      // Loop through processedGameData in batches of batchSize
      for (let i = 0; i < processedGameData.length; i += batchSize) {
        const batch = processedGameData.slice(i, i + batchSize);

        response = await sendRequest(
          {
            action: "batchAddGamePoints",
            gameRecords: batch.map((record) => ({
              walletAddress: record.Address,
              gameName: record.GameName,
              points: Number(record.Points),
            })),
          },
          `${process.env.REACT_APP_API_URL}`
        );

        // If there's an error with the response, break the loop and handle the error
        if (!response || response.status !== 200) {
          throw new Error("Error uploading batch");
        }
      }

      // Handle successful response
      toast.update("submitToast", {
        render: "All batches uploaded successfully!",
        type: "success",
        autoClose: 5000,
      });
    } catch (error) {
      // Handle errors and update the toast message
      console.error("Error uploading batches:", error);
      toast.update("submitToast", {
        render: "Error uploading batches.",
        type: "error",
        autoClose: 5000,
      });
    }
  };

  // Handle form reset - clear all uploaded files and reset data
  const handleGameReset = () => {
    setGameData([]);
    setProcessedGameData([]); // Clear processed data
    setGameFiles([]); // Clear the uploaded files
    setSelectedGameName(""); // Reset the selected game name
    setGamePointsAllocated(["350", "300", "200", "80", "40"]); // Reset points allocation
  };

  // Handle the logic for processing the data
  useEffect(() => {
    const fetchGameOptions = async () => {
      const apiKey =
        "69d410d68daa79bb89c137c5279d344f88d4817729251cf5f889fb7cd4b120d6";
      try {
        const response = await fetchRequest("https://games.zkcandyapi.com", {
          action: "getAllGames",
          apiKey: apiKey,
        });
        console.log('response: ' + JSON.stringify(response.data))

        if (response.data.data) {
          // Assuming the API returns a 'games' array with game names
          const fetchedGames = response.data.data.map((game) => ({
            value: game,
            label: game,
          }));
          setGameOptions(fetchedGames);
        } else {
          toast.error("Failed to fetch game options");
        }
      } catch (error) {
        console.error("Error fetching game options:", error);
        toast.error("Error fetching game options");
      }
    };

    fetchGameOptions();

    if (gameData.length > 0) {
      const totalPlayers = gameData.length;
      const top1PercentCount = Math.ceil(totalPlayers * 0.01);
      const top5PercentCount = Math.ceil(totalPlayers * 0.05) - top1PercentCount;
      const top10PercentCount = Math.ceil(totalPlayers * 0.1) - (top1PercentCount + top5PercentCount);
      const top20PercentCount = Math.ceil(totalPlayers * 0.2) - (top1PercentCount + top5PercentCount + top10PercentCount);

      // Sort the data by Ranking in ascending order
      const sortedGameData = [...gameData].sort((a, b) => {
        return parseInt(a.Ranking, 10) - parseInt(b.Ranking, 10);
      });

      // Allocate points based on ranking
      const updatedGameData = sortedGameData.map((record, index) => {
        let points = 0;
        
        // Top 1%
        if (index < top1PercentCount) {
          points = splitPoints.top1 
            ? parseFloat(gamePointsAllocated[0]) / top1PercentCount 
            : parseFloat(gamePointsAllocated[0]);
        } 
        // Top 5%
        else if (index < top1PercentCount + top5PercentCount) {
          points = splitPoints.top5 
            ? parseFloat(gamePointsAllocated[1]) / top5PercentCount 
            : parseFloat(gamePointsAllocated[1]);
        } 
        // Top 10%
        else if (index < top1PercentCount + top5PercentCount + top10PercentCount) {
          points = splitPoints.top10 
            ? parseFloat(gamePointsAllocated[2]) / top10PercentCount 
            : parseFloat(gamePointsAllocated[2]);
        } 
        // Top 20%
        else if (index < top1PercentCount + top5PercentCount + top10PercentCount + top20PercentCount) {
          points = splitPoints.top20 
            ? parseFloat(gamePointsAllocated[3]) / top20PercentCount 
            : parseFloat(gamePointsAllocated[3]);
        } 
        // Remaining 80%
        else {
          const remainingCount = totalPlayers - (top1PercentCount + top5PercentCount + top10PercentCount + top20PercentCount);
          points = splitPoints.top80 
            ? parseFloat(gamePointsAllocated[4]) / remainingCount 
            : parseFloat(gamePointsAllocated[4]);
        }

        return {
          ...record,
          Points: Math.round(points * 100) / 100, // Round to 2 decimal places
          GameName: selectedGameName || "Unknown Game",
        };
      });

      setProcessedGameData(updatedGameData);
    }
  }, [gameData, gamePointsAllocated, selectedGameName, splitPoints]);

  return (
    <div className="games-container p-4 pb-40">

      {/* Game Form */}
      <GameForm
        options={gameOptions} // Use hardcoded game options here
        gamePointsAllocated={gamePointsAllocated}
        handleSubmit={handleGameSubmit} // Pass the handleSubmit function
        handleReset={handleGameReset} // Pass the handleReset function
        setSelectedGameName={setSelectedGameName} // Pass the setSelectedGameName function
        setGamePointsAllocated={setGamePointsAllocated} // Pass the setGamePointsAllocated function
        splitPoints={splitPoints}
        setSplitPoints={setSplitPoints}
      />

      {/* File Uploader */}
      <GameFileUploader
        setGameData={setGameData} // Pass setGameData to handle file upload
        gameFiles={gameFiles}
        setGameFiles={setGameFiles}
      />

      {/* Game Data Table */}
      {processedGameData.length > 0 ? (
        <GameTable gameData={processedGameData} />
      ) : (
        <p className="form-card text-center m-4 -mt-10">
          No game data available
        </p>
      )}

      {/* Additional content can go here */}
    </div>
  );
};

export default Games;
