import React from 'react';
import Papa from 'papaparse';
import FileUploader from '../Components/FileUploader'; // Assuming FileUploader is a generic component for handling uploads

const GameFileUploader = ({ setGameData, gameFiles, setGameFiles }) => {
  // Handle file upload and parsing
  const handleUpload = (files) => {
    if (files.length > 0) {
      const file = files[0]; // Get the first file (allow only one)
      setGameFiles([file]); // Replace previous files with the new one

      // Parse CSV files
      Papa.parse(file, {
        header: true,
        complete: (results) => {
          // Filter out rows where all values are either null, undefined, or empty strings
          const filteredData = results.data.filter((row) => {
            return Object.values(row).some(
              (value) => value !== null && value !== undefined && value !== ""
            );
          });

          // Update parsed data in the parent state
          setGameData(filteredData);
        },
        error: (error) => {
          console.error('Error parsing CSV:', error);
        },
      });
    }
  };

  // Handle file removal
  const handleRemoveFile = (index) => {
    setGameFiles([]);
  };

  return (
    <div className='form-card z-10'>
      <label className='form-label' >Upload Game Files</label>
      <FileUploader
        onDrop={handleUpload}
        files={gameFiles}
        handleRemoveFile={handleRemoveFile}
      />
    </div>
  );
};

export default GameFileUploader;
