import React, { useState, useEffect } from 'react';
import { Tabs, Tab, Card, CardContent, Typography, CircularProgress } from '@mui/material';
import axios from 'axios';

const Analytics = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [activeUsers, setActiveUsers] = useState(null);
  const [totalUsers, setTotalUsers] = useState(null);
  const [percentageGrowth, setPercentageGrowth] = useState(null);
  const [loading, setLoading] = useState(true);

  // Generic sendRequest function for API calls
  async function sendRequest(data, url, method = "post", params = null) {
    console.log("data: ", data);
    const config = {
      method,
      url,
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(data),
    };
    if (params) config.params = params;
    return await axios.request(config).catch(function (error) {
      if (error.response) {
        return error.response;
      } else {
        return error;
      }
    });
  }

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const [activeUsersResponse, totalUsersResponse] = await Promise.all([
          sendRequest(
            { action: "getTotalUsersLastDay" },
            `${process.env.REACT_APP_API_URL}`
          ),
          sendRequest(
            { action: "getTotalUsers" },
            `${process.env.REACT_APP_API_URL}`
          )
        ]);

        if (activeUsersResponse.status === 200 && activeUsersResponse.data) {
          setActiveUsers(activeUsersResponse.data.data);
        }

        if (totalUsersResponse.status === 200 && totalUsersResponse.data) {
          setTotalUsers(totalUsersResponse.data.data);
        }

        // Calculate percentage growth
        if (activeUsersResponse.data.data && totalUsersResponse.data.data) {
          const newUsers = activeUsersResponse.data.data;
          const totalUsers = totalUsersResponse.data.data;
          const previousUsers = totalUsers - newUsers;

          if (previousUsers > 0) {
            const growth = ((newUsers / previousUsers) * 100).toFixed(2);
            setPercentageGrowth(growth);
          } else {
            setPercentageGrowth('N/A'); // Handle case where there were no previous users
          }
        }

      } catch (error) {
        console.error("Error fetching analytics data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const renderContent = () => {
    if (loading) {
      return <CircularProgress />;
    }

    switch (activeTab) {
      case 0:
        return <Typography variant="h4">{activeUsers}</Typography>;
      case 1:
        return <Typography variant="h4">{totalUsers}</Typography>;
      case 2:
        return <Typography variant="h4">{percentageGrowth}%</Typography>;
      default:
        return null;
    }
  };

  return (
    // <div className="analytics-container p-8 bg-white dark:bg-gray-900 rounded-lg shadow-md">
    //   <h1 className="text-2xl font-bold text-gray-900 dark:text-gray-200 mb-6">Analytics</h1>
    //   <Tabs value={activeTab} onChange={handleTabChange}>
    //     <Tab label="New Users Last Day" />
    //     <Tab label="Total Users" />
    //     <Tab label="Percentage Growth" />
    //   </Tabs>
    //   <Card>
    //     <CardContent>
    //       {renderContent()}
    //     </CardContent>
    //   </Card>
    // </div>

    <div className="analytics-container grid grid-cols-1 lg:grid-cols-3">
      <div className="card">
        <div className="card-header">New Wallets Last Day</div>
        <div className="card-content">{activeUsers}</div>
      </div>

      <div className="card">
        <div className="card-header">Total Wallets</div>
        <div className="card-content">{totalUsers}</div>
      </div>

      <div className="card">
        <div className="card-header">Percentage Growth</div>
        <div className="card-content">{percentageGrowth}%</div>
      </div>
    </div>
  );
};

export default Analytics;