import React, { useState, useEffect, Fragment } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from "../Contexts/AuthContext";
import axios from "axios";
import { Dialog, Transition } from '@headlessui/react';
import './Login.scss'; // Make sure to create this file
import ZookHeadphone from '../Images/zook_headphone.webp';
import ZookSide from '../Images/zook_side.webp';

export default function Login() {
  const { login } = useAuth();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  // Generic sendRequest function for API calls
  async function sendRequest(data, url, method = "post", params = null) {
    const config = {
      method,
      url,
      headers: {
        "Content-Type": "application/json",
      },
    };

    if (data) config.data = JSON.stringify(data)
    if (params) config.params = params
    return await axios.request(config).catch(function (error) {
      if (error.response) {
        return error.response;
      } else {
        return error;
      }
    });
  }

  const generateOTP = async () => {
    setLoading(true);
    try {
      const response = await sendRequest(
        { action: "generate", email },
        `${process.env.REACT_APP_CONSOLE_API_URL}`
      );
      if (response.data && response.data.message) {
        console.log(response.data);
        setOtpSent(true);
      }
    } catch (error) {
      console.error("Error generating OTP:", error);
    }
    setLoading(false);
  };

  const verifyOtp = async (email, otp) => {
    const action = 'verify'
    console.log('email, otp: ' + email + ' ' + otp)
    try {
      const response = await sendRequest({ email, otp, action }, 'https://console.zkcandy.io')
      console.log('response: ' + JSON.stringify(response.data.data))
      if (response.data.error !== null) {
        throw new Error('Login failed. Please try again.');
      }


      // Login successful
      const token = response.data.data.token;
      console.log('token: ' + JSON.stringify(token))
      login(token)
      navigate('/games')
    } catch (error) {
      console.log(error)
    }
  }

  const handleLogin = async () => {
    setLoading(true);
    try {
      const response = await sendRequest(
        { email, action: "generate" },
        'https://console.zkcandy.io'
      );
      console.log('response: ' + JSON.stringify(response));
      setIsDialogOpen(true);
    } catch (error) {
      console.error('Email OTP error: ', error);
    } finally {
      setLoading(false);
    }
  };

  const buttonStyle = {
    fontSize: "1.5rem",
    padding: "0.75rem 2rem",
    backgroundColor: "#4A90E2",
    color: "#fff",
    borderRadius: "8px",
    cursor: "pointer",
    transition: "background-color 0.3s ease",
    margin: "10px 0",
  };

  const inputStyle = {
    fontSize: "1.2rem",
    padding: "0.5rem",
    margin: "10px 0",
    borderRadius: "4px",
    border: "1px solid #ccc",
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const email = urlParams.get('email')
    const otp = urlParams.get('otp')
    if (email && otp) {
      verifyOtp(email, otp)
    }
  }, [])

  return (
    <main className={`login flex justify-center items-center h-screen ${loading ? 'loading' : ''}`}>

      <div className="console-container p-3">
        <div className="grid grid-cols-1">
          <div className="panel bg-[#5C26B1] p-8 md:pl-60 mt-40 md:mt-0 border-2 rounded-[.5rem] shadow-[4px_5px_0_#d954ff80] relative">
            <img src={ZookHeadphone} alt="Zook" className="m-auto w-[200px] md:w-[300px] md:absolute -left-[60px] -bottom-[50px] -mt-[200px] md:mt-0" />
            <div className="heading text-center md:text-left text-[2.8rem] md:text-[3rem] uppercase mb-2 -mt-2">$ Admin_Console</div>

            <div className="input flex items-center">
              <input
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="text-[1.5rem] rounded-l-[.3rem] border-2 w-full px-3 bg-[#d954ff33] text-white"
              />
              <button
                className="uppercase border-2 border-l-0 rounded-r-[.3rem] bg-[#D954FF] text-white text-[1.5rem] px-2 text-shadow-dark hover:bg-[#e176ff]"
                onClick={handleLogin}
                disabled={loading}
              >
                {loading ? "Sending..." : "Access"}
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Dialog for OTP confirmation */}
      <Transition appear show={isDialogOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => setIsDialogOpen(false)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-[#500067] backdrop-blur-sm bg-opacity-50" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="relative w-100 transform rounded-[.5rem] bg-[#F0E9F9] border-2 border-[#D954FF] p-6 md:pl-40 md:ml-20 text-left align-middle shadow-[4px_5px_0_#5c26b180] transition-all mt-20 md:mt-0">
                  <img src={ZookSide} alt="Zook" className="md:absolute w-[200px] md:w-[300px] -left-[130px] -bottom-[50px] scale-x-[-1] -mt-[150px] md:mt-0 leading-none" />
                  <Dialog.Title
                    as="h3"
                    className="text-[2.25rem] text-[#4100A5]"
                  >
                    OTP Sent
                  </Dialog.Title>

                  <p className="text-[1.25rem] text-[#7342BF] leading-none">
                    Email OTP sent,<br />
                    check your email for login link
                  </p>

                  <div className="mt-4">
                    <button
                      type="button"
                      className="btn btn-secondary text-[1.25rem]"
                      onClick={() => setIsDialogOpen(false)}
                    >
                      Got it, thanks!
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </main>
  );
}
